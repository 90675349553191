.about {
    margin-top: 0;
    padding: 260px 0 100px 0;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .about-bx {
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top: -100px;
    margin-bottom: 100px;
  }
  .about h2 {
    font-size: 45px;
    font-weight: 700;
  }
  .about img {
    border-radius: 50%;
  
  }
  .about p {
    color: #B8B8B8;
    font-size: 17px;
    letter-spacing: 0.8px;
    line-height: 2.5em;
    width: 96%;
  }