.social-buttons-container {
    text-align: center; 
}

.social-icon {
    display: inline-block; 
    justify-content: center; 

}

.social-icon a {
    margin: 0 10px;
}
